import {VideoPlayer} from "@plumeuk/shapeshift-common/v2/videoPlayer";
import {Box, BoxProps} from "@mui/material";
import {useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/v1/hooks";
import {ILesson} from "@plumeuk/shapeshift-types";
import {ReactPlayerProps} from "react-player";
import {forwardRef} from "react";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/v2/videoPlayer/videoPlayer";

const useStyles = makeStyles()((theme) => ({
	videoPlayerContainer: {
		marginBottom: theme.spacing(3.5),
		width: "100%"
	},
	videoPlayerBackdrop: {
		background: theme.palette.primary.dark,
		width: "100%",
		boxSizing: "border-box"
	},
	videoPlayer: {
		zIndex: 5,
		background: "black",
		opacity: 0,
		left: 0,
		top: -10,
		transition: "opacity .5s ease-in",
		right: "0px"
	}
}));

interface ICustomProps {
	lesson?: ILesson,
	currentTime?: number,
}

export type IProps = Omit<ReactPlayerProps, "url"> & BoxProps & ICustomProps;

export const LessonVideoPlayer = forwardRef<VideoPlayerRef, IProps>(function LessonVideoPlayer({lesson, currentTime}, ref) {
	const {classes} = useStyles();
	const mq = useMq()
	const [videoReady, setVideoReady] = useState(false);

	const handleReady = ():void => {
		setVideoReady(true)
	}

	if(!lesson?.videoUrl) return <></>

	return (
		<Box className={classes.videoPlayerContainer} >
			<Box className={classes.videoPlayerBackdrop}>
				<VideoPlayer
					ref={ref}
					onReady={handleReady}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={lesson.videoUrl}
					controls={true}
					height={mq.mobile ? "250px" : "550px"}
					currentTime={currentTime}
				/>
			</Box>
		</Box>
	);
});