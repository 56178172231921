import {FC, useCallback, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2/wysiwyg";
import {SurveyQuestionContainer} from "./SurveyQuestion/SurveyQuestionContainer";
import {SurveyQuestionsDataProvider} from "@plumeuk/shapeshift-common/v1/providers";
import {useSubmitSurveyResponse, useSurvey} from "@plumeuk/shapeshift-common/v1/hooks";
import {LinearProgress} from "@mui/material";
import {CurriculumContext} from "../../../../contexts/curriculumContext";
import {ISurvey, ISurveyAnswer} from "@plumeuk/shapeshift-types";
import {PageTitle} from "@plumeuk/shapeshift-common/v1/pageBase";

const useStyles = makeStyles()((theme) => ({
	surveyQuestions: {
	},
	surveyTitle: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(3)
	}
}));

interface IProps {
}

export const SurveyQuestions: FC<IProps> = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: surveySlug} = useParams();
	const {submitResponse, response} = useSubmitSurveyResponse();
	const navigate = useNavigate();
	const {curriculumDispatch} = useContext(CurriculumContext);
	const {survey} = useSurvey(courseSlug, surveySlug)


	useEffect(() => {
		if(!response || !surveySlug)
			return;

		curriculumDispatch({
			type: "setModuleComplete", module: {type: "survey", slug: surveySlug}, status: true})

		navigate("../complete")
	}, [response, surveySlug]);

	if(!courseSlug || !surveySlug) return <LinearProgress variant="query"/>

	return (
		<Box className={classes.surveyQuestions}>
			<PageTitle className={classes.surveyTitle} title={survey?.title} subtitle={survey?.subtitle} />
			<WYSIWYG>
				{survey?.content ?? ""}
			</WYSIWYG>
			<SurveyQuestionsDataProvider courseSlug={courseSlug} surveySlug={surveySlug}>
				{({surveyQuestions}) =>
					<SurveyQuestionContainer
						questions={surveyQuestions}
						response={survey?.response}
						onSubmit={(answers) => submitResponse(courseSlug, surveySlug, answers)}
					/>
				}
			</SurveyQuestionsDataProvider>
		</Box>
	);
}