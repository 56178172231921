import {useNavigate} from "react-router-dom";
import {CoursesBanner, CoursesTable} from "@plumeuk/shapeshift-common/v2/courses";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2/containers";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Typography} from "@mui/material";

const feather = "/PlumeFeather.svg"
const fallbackImage = "/fallbackImage.png"

const useStyles = makeStyles()((theme) => ({
	pageBase: {
	},
	dashboardContainer: {
		paddingTop: theme.spacing(5)
	}
}));

export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<CoursesBanner
				componentOverrides={{bannerTitle: <Typography variant="h1">My learning</Typography>}}
				bannerImage={feather}
			/>
			<DynamicContainer>
				<CoursesTable
					className={classes.dashboardContainer}
					fallbackImage={fallbackImage}
					onGoToCourseAction={courseId => { navigate("/course/" + courseId) }}
					onGoToLessonAction={(courseSlug, lessonSlug) => { navigate(`/course/${courseSlug}/lesson/${lessonSlug}`) }}
					onGoToCompletedCourseAction={courseId => { navigate(`/course/${courseId}/complete`) }}
					enableBookmarks={true}
				/>
			</DynamicContainer>
		</PageBase>
	);
}