import {FC} from "react";
import Main from "./Main";
import {NotificationDrawer} from "@plumeuk/shapeshift-common/v2/pageBase";
import {PageBaseProvider} from "./contexts/pageBaseContext";
import {IIconContext} from "@plumeuk/shapeshift-identity/types/IICon";
import {IconProvider} from "@plumeuk/shapeshift-identity";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import CircleIcon from "@mui/icons-material/Circle";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PersonIcon from "@mui/icons-material/Person";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import AttachFileOutlined from "@mui/icons-material/AttachFileOutlined";
import ClearAllOutlined from "@mui/icons-material/ClearAllOutlined";
import NotificationsUnreadOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import {defaultIconProviderIcons} from "@plumeuk/shapeshift-common/v1/icon"

const icons: Omit<IIconContext, "icon"> = {
	...defaultIconProviderIcons,
	menuIcon: <MenuIcon />,
	logoutIcon: <LogoutIcon />,
	circleIcon: <CircleIcon />,
	lockIcon: <LockIcon />,
	checkCircleOutlinedIcon: <CheckCircleOutlinedIcon />,
	chevronRightIcon: <ChevronRightIcon />,
	fileCopyIcon: <FileCopyIcon />,
	downloadForOfflineIcon: <DownloadForOfflineIcon />,
	restoreFromTrashIcon: <RestoreFromTrashIcon />,
	refreshIcon: <RefreshIcon />,
	cancelScheduleSendIcon: <CancelScheduleSendIcon />,
	contentPasteIcon: <ContentPasteIcon />,
	personIcon: <PersonIcon />,
	chairAltIcon: <ChairAltIcon />,
	engineeringIcon: <EngineeringIcon />,
	visibilityIcon: <VisibilityIcon />,
	visibilityOffIcon: <VisibilityOffIcon />,
	localOfferOutlinedIcon: <LocalOfferOutlinedIcon />,
	doneIcon: <DoneIcon />,
	closeIcon: <CloseIcon />,
	checkCircleIcon: <CheckCircleIcon />,
	errorIcon: <ErrorIcon />,
	cancelIcon: <CancelIcon />,
	infoIcon: <InfoIcon />,
	navigateNextIcon: <NavigateNextIcon />,
	starBorderIcon: <StarBorderIcon />,
	keyboardArrowRightIcon: <KeyboardArrowRightIcon />,
	dragIndicatorIcon: <DragIndicatorIcon />,
	autoFixNormalIcon: <AutoFixNormalIcon />,
	attachIcon: <AttachFileOutlined />,
	notificationsUnreadIcon: <NotificationsUnreadOutlined />,
	clearAllIcon: <ClearAllOutlined />
}

export const App: FC = () => (
	<IconProvider icons={icons}>
		<PageBaseProvider>
			<>
				<NotificationDrawer/>
				<Main />
			</>
		</PageBaseProvider>
	</IconProvider>
);

export default App;