import {FC, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, LinearProgress} from "@mui/material";
import {fetchIMSManifest, getModuleURLFromIMSManifestContent} from "@plumeuk/shapeshift-common/v1/common";
import "scorm-again/dist/scorm2004";
import {IScorm} from "@plumeuk/shapeshift-types";
import {useDebounce, useDepLog, useSetScormSessionData} from "@plumeuk/shapeshift-common/v1/hooks";
import {AuthContext} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()(() => ({
	container: {
		width: "100%",
		height: "500px",
		border: "none",
		overflow: "hidden"
	},
	iframe: {
		width: "100%",
		height: "100%",
		border: "none"
	},
	loading: {
		width: "100%"
	}
}));

interface IProps {
	manifestUrl: string;
	className?: string;
	onInitialize?: () => void;
	onFinish?: () => void;
	onCommit?: () => void;
	courseSlug?: string,
	scorm?: IScorm,
	onSetValue?: (prop: string, value: string) => void;
	onComplete?: () => void
}

export const ScormPlayer: FC<IProps> = ({
	manifestUrl,
	className,
	onCommit,
	onFinish,
	onInitialize,
	onSetValue,
	courseSlug,
	scorm,
	onComplete
}) => {
	const {classes, cx} = useStyles();
	const [loading, setLoading] = useState<boolean>(true);
	const [moduleUrl, setModuleUrl] = useState<string | undefined>(undefined);
	const [sessionData, setSessionData] = useState<Object>();
	const sessionDataDebounced = useDebounce(sessionData, 300);
	const {saveSessionData, apiResult, response} = useSetScormSessionData();
	const {user} = useContext(AuthContext);

	useDepLog( "apiResult", apiResult)
	useDepLog("response", response)

	useEffect(() => {
		if (!scorm || !user) return;

		try {
			console.log("[SCORM Player] Initializing SCORM 2004 API...");
			(window as any)["API_1484_11"] = new (window as any)["Scorm2004API"]({logLevel: 1});
			const API = (window as any)["API_1484_11"];

			console.log("[SCORM Player] Loading SCORM session data...", scorm.sessionData);
			API.loadFromJSON(scorm.sessionData);

			API.on("Initialize", () => {
				console.log("[SCORM Player] SCORM Initialize called.");
				onInitialize?.();
			});

			API.cmi.learner_id = user.username;
			API.cmi.learner_name = user.firstname + " " + user.lastname;
			(window as any).API_1484_11.cmi.learner_id = user.username;
			(window as any).API_1484_11.cmi.learner_name = user.firstname + " " + user.lastname;
			(window as any).API_1484_11.cmi.student_id = user.username;
			(window as any).API_1484_11.cmi.completion_status = "not attempted";

			API.on("Commit", () => {
				console.log("[SCORM Player] SCORM Commit called.");
				onCommit?.();
			});

			API.on("SetValue", (prop: string, value: string) => {
				console.log(`[SCORM Player] SCORM SetValue called. Property: ${prop}, Value: ${value}`);
				onSetValue?.(prop, value);
				if (prop === "cmi.completion_status" && value === "completed") {
					console.log("[SCORM Player] SCORM course completed.");
					onComplete?.();
				}
				setSessionData(prev => ({...prev, [prop.replace("cmi.", "")]: value}));
			});

			API.on("Terminate", () => {
				console.log("[SCORM Player] SCORM Terminate called.");
				onFinish?.();
			});

		} catch (ex) {
			console.error("[SCORM Player] Failed to initialize SCORM API or load session data.", ex);
		}

	}, [scorm, user]);

	useEffect(() => {
		if (courseSlug && scorm?.slug && sessionDataDebounced && !loading) {
			console.log("[SCORM Player] Saving session data...", sessionDataDebounced);
			saveSessionData(courseSlug, scorm.slug, sessionDataDebounced, {cache: false})
			// .catch(error => console.error("[SCORM Player] Failed to save session data.", error));
		}
	}, [sessionDataDebounced, loading]);

	useEffect(() => {
		console.log("[SCORM Player] Fetching IMS manifest...");
		fetchManifest();
	}, []);

	const fetchManifest = async (): Promise<void> => {
		try {
			const manifest = await fetchIMSManifest(manifestUrl);
			const moduleUrl = getModuleURLFromIMSManifestContent(manifestUrl, manifest);
			console.log("[SCORM Player] Module URL obtained from manifest:", moduleUrl);
			setModuleUrl(moduleUrl);
		} catch (error) {
			console.error("[SCORM Player] Failed to fetch IMS manifest or parse module URL.", error);
		}
	};

	return (
		<Box className={cx(classes.container, className)}>
			{(loading || !scorm) && <div className={classes.loading}>
				<LinearProgress variant="query"/>
			</div>}
			{scorm && moduleUrl && <iframe
				src={moduleUrl}
				allowFullScreen
				className={classes.iframe}
				onLoad={() => {
					console.log("[SCORM Player] Iframe loaded successfully.");
					setLoading(false);
				}}
				onError={(e) => console.error("[SCORM Player] Failed to load iframe.", e)}
			/>}
		</Box>
	)
};

export default ScormPlayer;
