import {Box, BoxProps} from "@mui/material";
import React, {FC, useEffect, useState, isValidElement, cloneElement, ReactNode, useContext, useRef, MouseEventHandler} from "react";
import {NavBar, SideMenu} from "@plumeuk/shapeshift-common/v2/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/v1/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {PageBaseContext} from "../../contexts/pageBaseContext";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";

const logo = "/PlumeLogo.svg"

interface ICustomProps {
	children: React.ReactNode,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean,
	disableSideBarOpen?: boolean,
	sideMenuWidthOverride?: string
}

interface IStyleProps {
	sideMenuOpen: boolean,
	sideMenuWidthOverride?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, sideMenuWidthOverride}) => ({
	pageBase: {
		height: "100%"
	},
	sideMenu: {
		width: sideMenuWidthOverride || theme.constants.sideMenuWidth,
		backgroundColor: theme.palette.background.default,
		top: theme.constants.toolbarHeight,
		position: "fixed",
		paddingBottom: theme.spacing(8),
		"& .MuiDrawer-paper": {
			paddingTop: 0
		},
		"& ::-webkit-scrollbar": {
			display: "none"
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.constants.toolbarHeight
	},
	contentContainer: {
		position: "absolute",
		overflowX: "hidden",
		overflowY: "scroll",
		boxSizing: "border-box",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%"
	},
	pageBody: {
		position: "relative",
		height: "100%",
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidthOverride || theme.constants.sideMenuWidth})` : "100%",
		left: sideMenuOpen ? (sideMenuWidthOverride || theme.constants.sideMenuWidth) : "0px",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		},
		transition: theme.transitionSpeeds.medium
	},
	appBar: {
		position: "sticky",
		transition: theme.transitionSpeeds.medium,
		background: theme.constants.navBackground
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;

type pages = "My learning" | "Course library" | "Notes" | "Content manager";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My learning": "/my-learning",
	"Course library": "/library",
	"Content manager": "/admin",
	"Notes": "/notes"
}

export const PageBase: FC<IProps> = ({sideBarContent, sideMenuWidthOverride, disableSideBar, children, className, ...props}) => {
	const mq = useMq();
	const location = useLocation();
	const [contextState, setContextState] = useContext(PageBaseContext)
	const sideMenuOpen = contextState.sidebarOpen;
	const {classes, cx} = useStyles({sideMenuOpen, sideMenuWidthOverride});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const setSideMenuOpen = (e: boolean): void => setContextState(prev => ({...prev, sidebarOpen: e}))
	const contentContainer = useRef<HTMLElement>(null);
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	useEffect(() => setFirstRender(false), [])

	useEffect(() => {
		if(!firstRender){
			setContextState(prev => ({...prev, contentContainerRef: contentContainer}))
		}
	}, [firstRender])

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	useEffect(() => {
		if(contextState.sidebarEnabled === false)
			setSideMenuOpen(false)
	}, [contextState]);


	const handlePageSelect = (page: pages): void => {
		setMobileNavOpen(false);
		navigate(routesMap[page]);
	}

	const handleContentClick: MouseEventHandler<HTMLDivElement> = (event): void => {
		const target = event.target as HTMLElement;
		if (target && "closest" in target && target.closest("[class*=\"courseSideMenuModule\"]")) {
			if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
		}
	}

	useEffect(() => {
		const tabs: pages[] = ["My learning", "Course library", "Notes"];
		if(contextState.ownedCohort){
			tabs.push("Content manager");
		}

		setTabs(tabs);
	}, [contextState.ownedCohort])

	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	const disableBurger = mq.desktop;

	const expandSidebar = (): void => {
		setContextState(prev => ({...prev, sidebarOpen: !prev.sidebarOpen}))
	};

	const isEnabledSidebar = !disableSideBar && contextState.sidebarEnabled

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			<Box
				className={classes.contentContainer}
				onClick={handleContentClick}
				ref={contentContainer}
			>
				<NavBar<pages>
					onBurgerAction={() => setMobileNavOpen(prev => !prev)}
					className={cx(classes.appBar)}
					tabs={tabs}
					disableBurger={disableBurger}
					mobileNavOpen={mobileNavOpen}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					editUserDetailsForm={EditUserDetailsForm}
				/>
				<SideMenu
					className={classes.sideMenu}
					open={sideMenuOpen}
					onExpandAction={isEnabledSidebar ? expandSidebar : undefined}
				>
					{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
				</SideMenu>
				<Box className={classes.pageBody}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
