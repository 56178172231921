import {TypographyStyleOptions} from "@mui/material/styles/createTypography";
import {ITypography, templateBreakpoints, templateTypography} from "@plumeuk/shapeshift-common/v2/theming";


export const assignTypographyProfile = (
	fontSizeProfile: keyof ITypography["sizes"],
	fontWeight?: keyof ITypography["weights"],
	lineHeight?: keyof ITypography["lineHeights"]
): TypographyStyleOptions => ({
	fontWeight: fontWeight ? templateTypography.weights[fontWeight] : templateTypography.weights.normal,
	fontSize: templateTypography.sizes[fontSizeProfile].default,
	...(templateTypography.sizes[fontSizeProfile]?.mobile ? {[`@media (max-width: ${templateBreakpoints.values?.sm}px)`]: {
		fontSize: templateTypography.sizes[fontSizeProfile]?.mobile
	}} : {}),
	lineHeight: lineHeight ? templateTypography.lineHeights[lineHeight] : templateTypography.lineHeights.primary
})
