import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersCoursesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminHeader, AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/v2/admin";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"course", IAdminUsersCoursesResponseEntry>["columns"] => ([
	{
		field: "title",
		flex: 1,
		headerName: "Course",
		width: 200,
		type: "string"
	},
	{
		field: "tags",
		flex: 1,
		headerName: "Tags",
		type: "string",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["tags"]}) => value?.join(", ")
	},
	{
		field: "languages",
		flex: 1,
		headerName: "Languages",
		filterable: false,
		type: "string",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	},
	{
		field: "createdAt",
		flex: 1,
		headerName: "Created",
		filterable: true,
		width: 100,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		flex: 1,
		headerName: "Published",
		width: 100,
		filterable: true,
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);


export const AdminCoursesList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminCoursesList}>
			<AdminHeader
				title="Courses"
				subtitle="Manage courses, enrol them into courses and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"course", IAdminUsersCoursesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/course"
				columns={headCells(locales.data)}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete
				type="course"
			/>
		</Box>
	);
}