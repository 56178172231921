import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Stack, Switch, Button, Typography, LinearProgress, CircularProgress, Slider} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {deepEqual} from "@plumeuk/shapeshift-common/v1/common";
import {Add, Edit} from "@mui/icons-material";
import {PageBaseContext} from "../../../contexts/pageBaseContext";
import {AdminHeader} from "@plumeuk/shapeshift-common/v2/admin";


const useStyles = makeStyles()((theme) => ({
	adminSettingsPage: {
	},
	title: {
		margin: "0 10px",
		fontWeight: 700,
		fontSize: "62px"
	},
	table: {
		borderCollapse: "collapse",
		width: "100%",

		"td": {
			padding: "20px",
			borderTop: "1px solid " + theme.palette.grey[200]
		},
		"td:nth-child(1) *": {
			fontWeight: 600,
			fontSize: "21px"
		},
		"td:nth-child(1)": {
			paddingLeft: 0
		}
	},
	switch: {
		width: 28,
		height: 16,
		padding: 0,
		display: "flex",
		"&:active": {
			"& .MuiSwitch-thumb": {
				width: 15
			},
			"& .MuiSwitch-switchBase.Mui-checked": {
				transform: "translateX(9px)"
			}
		},
		"& .MuiSwitch-switchBase": {
			padding: 2,
			"&.Mui-checked": {
				transform: "translateX(12px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					opacity: 1,
					backgroundColor: theme.palette.grey[200]
				}
			}
		},
		"& .MuiSwitch-thumb": {
			backgroundColor: theme.palette.primary.main,
			width: 12,
			height: 12,
			borderRadius: 6,
			transition: theme.transitions.create(["width"], {
				duration: 200
			})
		},
		"& .MuiSwitch-track": {
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: theme.palette.grey[200],
			boxSizing: "border-box"
		}
	},
	btnContainer: {
		paddingTop: "20px",
		gap: "10px",
		display: "flex",
		"& button:nth-child(2)": {
			backgroundColor: theme.palette.primary.main
		}
	},
	importedLibCountBadge: {
		padding: "5px 15px",
		borderRadius: "1000px",
		background: theme.palette.primary.main

	},
	importedLibraryTiles: {
		display: "flex",
		padding: "20px 0",
		flexWrap: "wrap",
		gap: "20px",
		"& > div": {
			background: theme.palette.grey[600],
			borderRadius: "5px",
			padding: "20px"
		}
	}
}));

interface ISettings {
	dailyLimit: number,
	courseVariety: number,
	publiclyVisible: boolean,
	extendedAccess?: {id: number, title: string}[]
}

export const AdminSettingsPage: FC = () => {
	const {classes} = useStyles();
	const [{ownedCohort}] = useContext(PageBaseContext);
	const ownedCohortId = (!ownedCohort) ? null : ownedCohort.id;
	const [settings, getSettings] = useApi<ISettings>("/api/cohort/" + ownedCohortId + "/settings") //> to do: migrate
	const [updateUserResponse, updateUser] = useApi<ISettings & {extendedAccess?: number[]}>()
	const [formData, setFormData] = useState<ISettings>({dailyLimit: 1, courseVariety: 4, publiclyVisible: false, extendedAccess: []});
	const {notify} = useContext(NotificationContext)
	const [newCohortLibraryImportModalOpen, setNewCohortLibraryImportModalOpen] = useState(false);
	const changeMade = !deepEqual(formData, settings.data);

	useEffect(() => {
		const ownedCohortId = (!ownedCohort) ? null : ownedCohort.id;
		if(ownedCohortId){
			getSettings("/api/cohort/" + ownedCohortId + "/settings")
		}
	}, [ownedCohort])

	useEffect(() => {
		if(settings.data){
			setFormData(settings.data)
		}
	}, [settings])

	useEffect(() => {
		if(updateUserResponse.statusCode === 200){
			notify("Your details have been updated", "Success", INotificationSeverity.success, 5000)
		}
	}, [updateUserResponse])

	const handleUpdate = (): void => {
		if(updateUserResponse.isLoading || !changeMade)
			return;

		const payload = {
			...formData,
			extendedAccess: formData.extendedAccess?.map(e => e.id)
		}
		updateUser({
			url: "/api/cohort/" + ownedCohortId + "/settings",
			data: payload,
			method: "PUT"
		})
	}

	return (
		<Box className={classes.adminSettingsPage}>
			<AdminHeader title="Settings" subtitle="Manage settings"/>
			<Box padding={6}/>
			{(!settings.data || settings.isLoading) ? <LinearProgress /> : <table cellPadding={0} className={classes.table}>
				<tr>
					<td style={{border: "none"}}><Typography>Training Feed Content</Typography></td>
					<td style={{border: "none"}}>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Box sx={{width: 150}}>
								<Slider
									defaultValue={1}
									step={1}
									max={3}
									min={1}
									value={formData.dailyLimit}
									onChange={(_e, value) => setFormData(prev => ({...prev, dailyLimit: value as number}))}
									valueLabelDisplay="auto"
									getAriaValueText={e => e.toString()}
									marks={([1,2,3]).map(e => ({value: e, label: e.toString()}))}

								/>
							</Box>
							<Typography sx={{marginTop: "8px"}}>Daily assignment</Typography>
						</Box>
					</td>
				</tr>

				<tr>
					<td style={{border: "none"}}></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Box sx={{width: 150}}>
								<Slider
									defaultValue={4}
									step={1}
									max={6}
									min={1}
									value={formData.courseVariety}
									onChange={(_e, value) => setFormData(prev => ({...prev, courseVariety: value as number}))}
									valueLabelDisplay="auto"
									getAriaValueText={e => e.toString()}
									marks={([1,2,3,4,5,6]).map(e => ({value: e, label: e.toString()}))}
								/>
							</Box>
							<Typography sx={{marginTop: "8px"}}>Course Variety</Typography>
						</Box>
					</td>
				</tr>

				<tr>
					<td><Typography>Privacy and Content Sharing</Typography></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Switch className={classes.switch} checked={formData.publiclyVisible} onChange={e => setFormData(prev => ({...prev, publiclyVisible: e.target.checked}))}/>
								<Typography>Publicly Visible</Typography>
							</Stack>
						</Box>
					</td>
				</tr>
				<tr>
					<td style={{border: "none"}}></td>
					<td>
						<Box sx={{display: "flex", gap: "10px"}}>
							<Typography>Imported Libraries: </Typography>
							{((formData.extendedAccess?.length ?? 0) == 0) && <Typography sx={{fontWeight: 600}}>none</Typography>}

						</Box>
						<Box className={classes.importedLibraryTiles}>
							{formData.extendedAccess?.map(e => <Box key={e.id}><Typography>{e.title}</Typography></Box>)}
						</Box>
						<Button onClick={() => setNewCohortLibraryImportModalOpen(true)} sx={{marginTop: "20px"}}>
							<Typography>{(formData.extendedAccess?.length ?? 0) > 0 ? "Modify Selection" : "Add Library"}</Typography>
							{(formData.extendedAccess?.length ?? 0) > 0 ? <Edit /> : <Add />}
						</Button>
					</td>
				</tr>

				<tr>
					<td style={{border: "none"}}></td>
					<td style={{border: "none"}}>
						<Box padding={5}></Box>
						<Box className={classes.btnContainer}>
							<Button disabled={!changeMade} sx={{width: "190px"}} onClick={() => handleUpdate()}>
								{updateUserResponse.isLoading ? <CircularProgress /> : "Save preferences"}
							</Button>
							{settings.data && <Button disabled={!changeMade} onClick={() => settings.data && setFormData(settings.data)}>Cancel changes</Button>}
						</Box>
					</td>
				</tr>
			</table>}
		</Box>
	);
}