import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {ILessonEntity, ILessonEntityPayload} from "@plumeuk/shapeshift-types";
import {useNavigate, useParams} from "react-router-dom";
import {AdminEntityPageContainer, AdminInputText} from "@plumeuk/shapeshift-common/v2/admin";
import {APIState} from "@plumeuk/shapeshift-identity";
import {AdminFieldContainer} from "@plumeuk/shapeshift-common/v2/admin";
import Editor from "../Editor/Editor";

interface IProps {
}

const useStyles = makeStyles()((theme) => ({
	adminLessonPage: {

	}
}));

const template: ILessonEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminLessonPage: FC<IProps> = () => {
	const {lessonId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = lessonId ? parseInt(lessonId) : undefined;

	const ILessonEntityToILessonEntityPayload = (e: ILessonEntity): ILessonEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, subtitle} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, subtitle, videoData
		}
		return payload;
	}

	const handleOnSaveResponse = (e: APIState<ILessonEntity>): void => {
		if(!e.data)
			return;
		navigate("../" + e.data.id.toString())
	}

	const handleOnGetResponse = (entityApiResponse: APIState<ILessonEntity>): void => {
		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<ILessonEntity>): void => {
		if(deleteApiResponse.statusCode === 200){
			navigate("../")
		}
	}

	return (
		<Box className={classes.adminLessonPage}>
			<AdminEntityPageContainer<ILessonEntity, "lesson">
				entityId={lessonId ? parseInt(lessonId) : undefined}
				entityToPayload={ILessonEntityToILessonEntityPayload}
				template={template}
				type="lesson"
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
			>
				{({formData, setFormData, entity}) => <>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Lesson title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Lesson subtitle"}/>
					<AdminFieldContainer label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
					{/* <AdminFieldContainer label="Video"><AdminVideoUpload videoThumbnailUrl={entity?.videoThumbnailUrl} videoUrl={entity?.videoUrl} value={(formData as any)?.videoData} entity={entity} initialValue={(entity as any)?.videoData} onChange={e => setFormData(prev => ({...prev, "videoData": e}))}/></AdminFieldContainer> */}
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}