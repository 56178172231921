import {QuizQuestionContainer as SSQuizQuestionContainer} from "@plumeuk/shapeshift-common/v2/quiz";
import {IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

interface IProps {
	questions?: IQuizQuestion[],
	onSubmit: (answers: IQuizAnswer[]) => void,
	submitResponseResultId?: string
}

export const QuizQuestionContainer: FC<IProps> = ({questions, onSubmit, submitResponseResultId}) => {
	const navigate = useNavigate();
	const [activeQuestionPos, setActiveQuestionPos] = useState<number>(0);

	useEffect(() => {
		if(questions && questions?.length > 0){
			setActiveQuestionPos(0);
		}
	}, [questions]);

	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])

	const handlePrevious = (): void => {
		if(activeQuestionPos === 0) {
			navigate(-1);
			return;
		}
		setActiveQuestionPos(prev => prev - 1);
	}

	return (
		<SSQuizQuestionContainer
			activeQuestionPos={activeQuestionPos}
			questions={questions}
			onSubmit={answers => {onSubmit(answers)}}
			onNextQuestionAction={() => setActiveQuestionPos(prev => prev + 1)}
			onPreviousQuestionAction={handlePrevious}
		/>
	);
}