import {FC, useContext} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {CourseLibraryBanner} from "./CourseLibraryBanner";
import {useNavigate} from "react-router-dom";
import {Library} from "@plumeuk/shapeshift-common/v2/library";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2/containers";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";

export const CourseLibraryPage: FC = () => {
	const navigate = useNavigate();
	const {notify} = useContext(NotificationContext);

	return (
		<PageBase disableSideBar>
			<DynamicContainer size="wide">
				<CourseLibraryBanner />
				<Library
					includeEnrolled
					enableProgressionBar={true}
					onGoToItemAction={item => {
						if (item?.__type === "plugin::strapi-plugin-shapeshift-cms.course") {
							navigate(`/library/${item.slug}`)
							return;
						}
						notify("", "Learning path not yet supported", INotificationSeverity.error, 5000);
					}}
				/>
			</DynamicContainer>
		</PageBase>
	);
}