import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleTab} from "@plumeuk/shapeshift-common/v2/module";
import {PageTitle} from "@plumeuk/shapeshift-common/v2/pageBase";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2/wysiwyg";
import {Files} from "@plumeuk/shapeshift-common/v2/files";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2/containers";
import {NotesSection} from "@plumeuk/shapeshift-common/v2/notes";
import {CommentsSection} from "@plumeuk/shapeshift-common/v2/comments";
import {ModuleNotAvailable, ModuleTabs, ModuleCompleteButton} from "@plumeuk/shapeshift-common/v2/module";
import {Links} from "@plumeuk/shapeshift-common/v1/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/v2/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/v1/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/v1/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {DynamicComponent} from "@plumeuk/shapeshift-common/v2/dynamicComponents";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
	},
	activeTab: {
	},
	lessonContent: {
		"& img": {
			// To do: potentially replace these styles with flexible components
			minWidth: theme.containers.constrained,
			...theme.helpers.alignWide,
			[theme.breakpoints.down("lg")]: {
				maxWidth: "100%",
				minWidth: "100%"
			},
			[theme.breakpoints.down("md")]: {
				...theme.helpers.alignFull,
				borderRadius: 0
			}
		}
	},
	extrasTitle: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	moduleContainer: {
		minHeight: `calc(60vh - ${theme.constants.toolbarHeight} - ${theme.constants.fullButtonHeight} - ${theme.constants.gutterSpacing})` // To do: find a nicer approach
	},
	goToNextLesson: {
		marginTop: theme.spacing(3),
		marginLeft: "auto",
		marginRight: "auto"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0
		}
	},
	nextLessonButton: {
		borderRadius: 0
	}
}));

export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();
	const location = useLocation();

	useEffect(() => {
		if (courseSlug && lessonSlug && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, lessonSlug, curriculum])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if (e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000, false);
		}
		else if (!e.isLoading && !e.isError && e.statusCode === 200) {
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status
			})

			if (e?.data?.complete) {
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	useEffect(() => {
		setTab("overview")
	}, [lessonSlug])

	const videoRef = useRef<VideoPlayerRef>(null);

	return (
		<>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} populate={["dynamicContent.*", "files"]}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{lesson?.videoUrl && <>
							<LessonVideoPlayer ref={videoRef} lesson={lesson} currentTime={location?.state?.timestamp ?? undefined} />
						</>}
						<Box>
							<Box>
								<DynamicContainer noGutter>
									<ModuleTabs moduleTabs={[{label: "Lesson", value: "overview"}, {label: "Discussions", value: "comments"}, {label: "Notes", value: "notes"}]} tabSelected={tabSelected} onChange={tab => setTab(tab)} />
								</DynamicContainer>
							</Box>
						</Box>
						{tabSelected === "overview" && <>

							<DynamicContainer className={classes.moduleContainer} size="constrained">
								<PageTitle title={lesson?.title} subtitle={lesson?.subtitle} />
								<WYSIWYG className={classes.lessonContent}>
									{lesson?.content ?? ""}
								</WYSIWYG>
							</DynamicContainer>
							<DynamicComponent
								components={lesson?.dynamicContent}
							/>
							<DynamicContainer className={lesson?.files || (lesson?.links && lesson.links.length > 0) ? classes.moduleContainer : undefined} size="constrained">
								{lesson?.files && <>
									<Typography className={classes.extrasTitle} variant="h4">Files</Typography>
									<Files files={lesson.files} />
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.extrasTitle} variant="h4">Links</Typography>
									<Links links={lesson.links} />
								</>}
							</DynamicContainer>
						</>}
						{tabSelected === "comments" &&
							<DynamicContainer className={classes.moduleContainer} size="constrained">
								<CommentsSection
									courseSlug={courseSlug}
									moduleType={"lesson"}
									moduleSlug={lessonSlug}
								/>
							</DynamicContainer>
						}
						{tabSelected === "notes" &&
							<DynamicContainer className={classes.moduleContainer} size="constrained">
								<NotesSection
									courseSlug={courseSlug}
									moduleType="lesson"
									moduleSlug={lessonSlug}
									videoRef={videoRef}
									onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}}
								/>
							</DynamicContainer>
						}
						{courseSlug && lesson?.slug && !lesson?.complete &&
							<ModuleCompleteButton
								variant="text"
								size="fullWidth"
								courseSlug={courseSlug}
								moduleSlug={lesson.slug}
								type="lesson"
								onApiUpdate={handleCompletion}
							/>
						}
						{courseSlug && lesson?.slug && lesson?.complete && firstAvailableModule &&
							<Button
								variant="text"
								size="fullWidth"
								className={classes.nextLessonButton}
								onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
							>
								Next module
							</Button>}
						{courseSlug && lesson?.slug && lesson?.complete && !firstAvailableModule && <Button
							variant="text"
							size="fullWidth"
							className={classes.goToNextLesson}
							onClick={() => navigate("/my-learning")}
						>
							Go back to your courses
						</Button>}

					</>}
					{apiResult.statusCode === 403 && <DynamicContainer size="constrained" align="center" className={classes.notAvailableContainer} noGutter>
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
						{firstAvailableModule &&
							<Button
								size="large"
								variant="outlined"
								className={classes.goToNextLesson}
								onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
							>
								Go to next available lesson
							</Button>}
						{firstAvailableModule === false && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate("/my-learning")}
						>
							Go back to your courses
						</Button>}
					</DynamicContainer>}
				</>}
			</LessonDataProvider>
		</>
	);
}