import {createTheme, Shadows} from "@mui/material";
import {templateBreakpoints, templatePalette, templateConstants, templateTransitions, templateSpacing, templateTypography, toolbarHeight} from "@plumeuk/shapeshift-common/v2/theming";
import {assignTypographyProfile} from "./assignTypographyProfile"

export const adminTheme = createTheme({
	toolbarHeight,
	breakpoints: templateBreakpoints,
	spacing: (factor: number) => `${templateSpacing.spacer * factor}rem`,
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		background: templatePalette?.background,
		primary: templatePalette.primary,
		secondary: templatePalette.secondary,
		success: templatePalette.success,
		error: templatePalette.error,
		text: templatePalette.text
	},
	typography: {
		fontFamily: templateTypography.fonts.primary,
		body1: assignTypographyProfile("paragraph", "normal", "primary"),
		body2: assignTypographyProfile("paragraph", "bold", "primary"),
		h1: assignTypographyProfile("h1", "semiBold", "secondary"),
		h2: assignTypographyProfile("h2", "semiBold", "secondary"),
		h3: assignTypographyProfile("h3", "semiBold", "secondary"),
		h4: assignTypographyProfile("h4", "semiBold", "secondary"),
		h5: assignTypographyProfile("h5", "semiBold", "secondary"),
		h6: assignTypographyProfile("h6", "semiBold", "secondary"),
		subtitle1: assignTypographyProfile("subtitle1", "bold", "secondary"),
		subtitle2: assignTypographyProfile("subtitle2", "normal", "secondary"),
		caption: assignTypographyProfile("caption", "normal", "caption"),
		button: assignTypographyProfile("button", "bold", "secondary"),
		highlight: assignTypographyProfile("highlight", "bold", "caption"),
		small: assignTypographyProfile("small", "bold", "caption")
	},
	borderRadius: {
		small: templateConstants.borderRadius.small,
		medium: templateConstants.borderRadius.medium,
		large: templateConstants.borderRadius.large
	},
	borderWidth:{
		small: templateConstants.borderWidth.small,
		medium: templateConstants.borderWidth.medium,
		large: templateConstants.borderWidth.large
	},
	constants: {
		salesBarWidth: templateConstants.constants.salesBarWidth,
		sideMenuWidth: templateConstants.constants.sideMenuWidth,
		notificationWidth: templateConstants.constants.notificationWidth,
		bannerHeight: templateConstants.constants.bannerHeight,
		toolbarHeight: templateConstants.constants.toolbarHeight,
		fullButtonHeight: templateConstants.constants.fullButtonHeight,
		gutterSpacing: templateConstants.constants.gutterSpacing
	},
	thumbnails: {
		small: templateConstants.thumbnails.small,
		medium: templateConstants.thumbnails.medium,
		large: templateConstants.thumbnails.large
	},
	boxShadow: {
		small: templateConstants.boxShadow.small,
		medium: templateConstants.boxShadow.medium,
		large: templateConstants.boxShadow.large
	},
	iconSizes: {
		small: templateConstants.iconSizes.small,
		medium: templateConstants.iconSizes.medium,
		large: templateConstants.iconSizes.large,
		extraLarge: templateConstants.iconSizes.extraLarge
	},
	containers: {
		constrained: templateConstants.containers.constrained,
		standard: templateConstants.containers.standard,
		wide: templateConstants.containers.wide
	},
	transitionSpeeds: {
		fast: templateConstants.transitionSpeeds.fast,
		medium: templateConstants.transitionSpeeds.medium,
		slow: templateConstants.transitionSpeeds.slow
	},
	transitionEffects: {
		hoverCard: templateTransitions.hoverCard,
		svgHoverFill: templateTransitions.svgHoverFill,
		hoverFade: templateTransitions.hoverFade,
		fadeAccordion: templateTransitions.fadeAccordion,
		expandAccordion: templateTransitions.expandAccordion
	},
	helpers: {
		absoluteCenter: templateConstants.helpers.absoluteCenter,
		fullScreenBar: templateConstants.helpers.fullScreenBar
	},
	navBar: {
		statusprofile: {
			backgroundColor: templatePalette.primary.main
		}
	},
	dynamicContainer: {
		root: {
		},
		constrained: {
		},
		wide: {
		},
		center: {
		}
	},
	code: {
		root: {
			...assignTypographyProfile("caption", "normal", "primary")
		}
	},
	blockquote: {
		root: {
		}
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variant: "body1"
			},
			styleOverrides: {
				gutterBottom: {
					marginBottom: `${templateSpacing.spacer * 1.25}rem`
				}
			},
			variants: [
				{
					props: {
						//Custom MuiTypography variant for small text
						variant: "small"
					},
					style: {
						...assignTypographyProfile("small")
					}
				}
			]
		},
		MuiPopover: {
			styleOverrides: {
				root: {
					borderRadius: templateConstants.borderRadius.small,
					boxShadow: templateConstants.boxShadow.medium
				},
				paper: {
					borderRadius: templateConstants.borderRadius.small,
					boxShadow: templateConstants.boxShadow.medium
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					...assignTypographyProfile("caption")
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					height: `${templateConstants.constants.toolbarHeight}`,
					padding: 0
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					"&:hover": {
						cursor: "pointer"
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					minHeight: "auto",
					...assignTypographyProfile("caption", "normal", "primary")
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					borderRadius: `${templateConstants.borderRadius.small}`,
					padding: `${templateSpacing.spacer}rem ${templateSpacing.spacer * 1.75}rem`
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: {
					paddingLeft: `${templateSpacing.spacer * 1.25}rem`,
					"&.Mui-focused": {
						paddingLeft: 0,
						color: templatePalette.secondary.main
					},
					"&.MuiInputLabel-shrink": {
						paddingLeft: 0
					}
				}
			}
		},
		MuiInputAdornment: {
			styleOverrides: {
				positionStart: {
					marginLeft: 0,
					marginRight: `${templateSpacing.spacer * 1.75}rem`
				},
				positionEnd: {
					marginLeft: `${templateSpacing.spacer * 2}rem`,
					marginRight: 0
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: "auto",
					minHeight: "auto",
					padding: `${templateSpacing.spacer * 2}rem ${templateSpacing.spacer * 2.25}rem`,
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: templatePalette.secondary.main
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderWidth: templateConstants.borderWidth.small,
						borderColor: templatePalette.secondary.main
					},
					"&.Mui-focused label": {
						color: templatePalette.secondary.main
					},
					"&.Mui-focused ::placeholder": {
						opacity: 0.8,
						color: templatePalette.primary.main
					},
					"& svg path": {
						transition: templateConstants.transitionSpeeds.fast
					},
					"&.Mui-error svg path": {
						stroke: templatePalette.error.main,
						transition: templateConstants.transitionSpeeds.fast
					},
					"&.Mui-error .MuiOutlinedInput-notchedOutline": {
						borderColor: templatePalette.error.main
					}
				},
				sizeSmall: {
					padding: `${templateSpacing.spacer * 1.5}rem ${templateSpacing.spacer * 1.75}rem`
				},
				notchedOutline: {
					transition: templateConstants.transitionSpeeds.fast,
					borderRadius: templateConstants.borderRadius.small,
					borderColor: `${templatePalette.primary.light}`
				},
				input: {
					padding: 0
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					paddingLeft: 0,
					paddingRight: 0
				},
				select: {
					padding: `0 ${templateSpacing.spacer * 1.25}rem`
				},
				icon: {
					color: templatePalette.primary.main,
					fontSize: "1rem"
				}
			}
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingLeft: 0
				}
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					...assignTypographyProfile("paragraph", "normal", "primary"),
					padding: "0 0"
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: templateConstants.borderRadius.medium,
					height: `${templateSpacing.spacer}rem`,
					marginTop: `${templateSpacing.spacer}rem`,
					marginBottom: `${templateSpacing.spacer}rem`,
					backgroundColor: templatePalette.primary.dark + "20"
				},
				bar: {
					background: `linear-gradient(90deg, ${templatePalette.success.light} 0%, ${templatePalette.success.main} 100%)`,
					borderRadius: templateConstants.borderRadius.medium
				},
				query: {
					maxWidth: `${templateSpacing.spacer * 30}rem`,
					width: "100%",
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					height: `${templateSpacing.spacer * 0.75}rem`
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					color: templatePalette.text.gray,
					padding: `${templateSpacing.spacer * 1.75}rem ${templateSpacing.spacer * 1.5}rem`,
					"&.Mui-selected": {
						color: templatePalette.primary.main,
						borderBottom: `2px solid ${templatePalette.secondary.main}`,
						opacity: "1"
					}
				}
			}
		},
		MuiBadge: {
			styleOverrides: {
				root: {
					backgroundColor: templatePalette.secondary.main
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxSizing: "border-box",
					overflow: "hidden"
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: templatePalette.background.default
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					position: "relative",
					backgroundColor: templatePalette.background.default,
					borderStyle: "solid",
					boxSizing: "border-box",
					borderWidth: templateConstants.borderWidth.small,
					borderColor: templatePalette.primary.light,
					borderRadius: templateConstants.borderRadius.small,
					height: "100%"
				}
			}
		},
		MuiCardMedia: {
			styleOverrides: {
				root: {
					position: "relative"
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					height: "100%",
					position: "relative",
					paddingBottom: `${templateSpacing.spacer * 10}rem`
				}
			}
		},
		MuiCardActionArea: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					padding: `${templateSpacing.spacer * 1.75}rem ${templateSpacing.spacer * 2}rem`,
					position: "absolute",
					top: "auto",
					bottom: 0,
					left: 0,
					borderTop: `1px solid ${templatePalette.primary.light}`,
					"& svg": {
						position: "absolute",
						right: `${templateSpacing.spacer * 2}rem`,
						top: "50%",
						transform: "translateY(-50%)"
					}
				},
				focusHighlight: {
					visibility: "hidden"
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: templatePalette.secondary.main,
					height: "4px"
				}
			}
		},
		MuiIcon: {
			styleOverrides: {
				root: {
					fontSize: templateConstants.iconSizes.medium
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//Root button styles
					...assignTypographyProfile("button", "bold", "caption"),
					borderRadius: templateConstants.borderRadius.large,
					alignSelf: "flex-start",
					textTransform: "none",
					minWidth: "auto",
					padding: `${templateSpacing.spacer * 1.75}rem ${templateSpacing.spacer * 3.25}rem`
				},
				startIcon: {
					marginRight: `${templateSpacing.spacer * 1.5}rem`
				},
				endIcon: {
					marginLeft: `${templateSpacing.spacer * 1.5}rem`
				},
				iconSizeSmall: {
					"& > *:first-child": {
						fontSize: templateConstants.iconSizes.small
					}
				},
				iconSizeMedium: {
					"& > *:first-child": {
						fontSize: templateConstants.iconSizes.medium
					}
				},
				iconSizeLarge: {
					"& > *:first-child": {
						fontSize: templateConstants.iconSizes.large
					}
				},
				sizeLarge: {
					//Large button spacings, fonts
					padding: `${templateSpacing.spacer * 2.25}rem ${templateSpacing.spacer * 4.25}rem ${templateSpacing.spacer * 2.3}rem`
				},
				sizeSmall: {
					//Small button spacings, fonts
					...assignTypographyProfile("subtitle2", "bold"), //To do: Maybe better naming instead of subtitle2? 
					padding: `${templateSpacing.spacer * 0.4}rem ${templateSpacing.spacer * 1.75}rem ${templateSpacing.spacer * 0.35}rem`,
					minWidth: 0
				},
				text: {
					//Default MuiButton with no variants set
					backgroundColor: templatePalette.secondary.main,
					color: templatePalette.text.light,
					borderWidth: templateConstants.borderWidth.small,
					borderColor: templatePalette.secondary.main,
					"& svg": {
						stroke: templatePalette.text.light
					},
					"&:hover": {
						backgroundColor: templatePalette.secondary.dark,
						borderColor: templatePalette.secondary.dark,
						color: templatePalette.text.light,
						"& svg": {
							stroke: templatePalette.text.light
						}
					},
					"&:disabled": {
						color: templatePalette.text.light
					}
				},
				outlined: {
					//Outlined MuiButton
					backgroundColor: "transparent",
					color: templatePalette.secondary.main,
					borderWidth: templateConstants.borderWidth.small,
					borderColor: templatePalette.secondary.main,
					"&:hover": {
						color: templatePalette.secondary.dark,
						backgroundColor: templatePalette.secondary.light,
						borderColor: templatePalette.secondary.dark
					},
					"&:disabled": {
						color: templatePalette.secondary.main
					}
				}
			},
			variants: [
				{
					props: {
						//Custom MuiButton variant for fullwidth buttons
						size: "fullWidth"
					},
					style: {
						width: "100%",
						height: `${templateConstants.constants.fullButtonHeight}`,
						padding: `${templateSpacing.spacer * 2.4}rem ${templateSpacing.spacer * 4.25}rem`
					}
				},
				{
					props: {
						//Custom MuiButton variant for tag buttons
						variant: "tag"
					},
					style: {
						borderWidth: templateConstants.borderWidth.small,
						borderColor: `${templatePalette.secondary.main}20`,
						color: templatePalette.secondary.main,
						backgroundColor: templatePalette.secondary.light
					}
				},
				{
					props: {
						//Custom MuiButton variant for single sign-on buttons
						variant: "external"
					},
					style: {
						color: templatePalette.primary.main,
						backgroundColor: templatePalette.primary.light,
						borderColor: templatePalette.primary.light,
						borderWidth: templateConstants.borderWidth.small,
						borderRadius: templateConstants.borderRadius.small
					}
				},
				{
					props: {
						//Custom MuiButton variant for blank buttons
						variant: "link"
					},
					style: {
						border: 0,
						padding: 0,
						backgroundColor: "transparent",
						color: templatePalette.secondary.main,
						"& svg path": {
							stroke: templatePalette.secondary.main,
							fontSize: templateConstants.iconSizes.large
						},
						"&:hover": {
							backgroundColor: "transparent",
							textDecoration: "underline"
						}
					}
				},
				{
					props: {
						//Custom MuiButton variant for swiper buttons
						variant: "swiper"
					},
					style: {
						border: 0,
						padding: `${templateSpacing.spacer * 2}rem`,
						backgroundColor: templatePalette.primary.main,
						color: templatePalette.text.light,
						"&:hover": {
							backgroundColor: templatePalette.secondary.main
						}
					}
				}
			]
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					cursor: "pointer",
					textAlign: "center"
				}
			},
			variants: [
				{
					props: {
						variant: "small"
					},
					style: {
						width: `${templateSpacing.spacer * 5}rem`,
						height: `${templateSpacing.spacer * 5}rem`,
						borderRadius: templateConstants.borderRadius.large,
						background: templatePalette.secondary.main
					}
				}
			]
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					background: "transparent",
					border: `${templateConstants.borderWidth.small} solid`,
					borderColor: templatePalette.primary.light,
					borderRadius: templateConstants.borderRadius.small,
					padding: `${templateSpacing.spacer * 0.25}rem 0`,
					"&:first-of-type": {
						borderRadius: templateConstants.borderRadius.small
					},
					"&:last-of-type": {
						borderRadius: templateConstants.borderRadius.small
					}
				}
			}
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					padding: `${templateSpacing.spacer * 0.2}rem ${templateSpacing.spacer * 1.75}rem`
				},
				expanded: {
				}
			}
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: `0 ${templateSpacing.spacer * 1.5}rem`
				}
			}
		},
		MuiCollapse: {
			styleOverrides: {
				root: {
					...templateTransitions.fadeAccordion.default
				},
				entered: {
					...templateTransitions.fadeAccordion.active
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: templateConstants.borderRadius.large,
					backgroundColor: templatePalette.secondary.main,
					color: templatePalette.text.light,
					padding: `${templateSpacing.spacer * 0.25}rem ${templateSpacing.spacer * 1.25}rem ${templateSpacing.spacer * 0.3}rem`,
					...assignTypographyProfile("small", "normal", "primary")
				}
			}
		}
	}
});
